import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import { Button, Modal } from "antd"
import ImageGallery from "../components/ImageGallery"

const Solitaires = () => {

//Hooks
const [dataContact, setDataContact] = useState(false)

//Modal
const [showModalContact, setShowModalContact] = useState(false)

const showModalContactForm = (value_i) => {
  setShowModalContact(true)
  setDataContact(value_i)
}

const handleContactFormOk = e => {
  setShowModalContact(false)
}

const handleContactFormCancel = e => {
  setShowModalContact(false)
}

return (
  <>
  <Layout>
    <SEO title="Solitaires" />

    <div className="slideshow">
      <div className="usp">
        <div className="usp-txt-header">Solitaires</div>
        <div  className="usp-txt">Une demande particulière… <br/>A l'Amour de votre vie.</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>

    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Solitaires</h2>

    <h3 className="h3-mobile" style={{textAlign: 'center'}}>Choisissez le solitaire rapidement en boutique<br/>ou laissez-nous le soin de le créer d'après vos envies.</h3>

    <p className="p-2 p-font mobile-display" style={{textAlign: 'center'}}>Choisissez le solitaire rapidement en boutique<br/>ou laissez-nous le soin de le créer d'après vos envies.</p>

    <section>
      <ImageGallery id="solitaires" />

      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Solitaires') }>Nous contacter</Button></div>
      <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
        visible={ showModalContact }
        onOk={ handleContactFormOk }
        onCancel={ handleContactFormCancel }
        okButtonProps={{ style: { display: 'none' } }}
      >

        <ContactForm page={dataContact} />

      </Modal>

    </section>

  </Layout>
  </>
)
}

export default Solitaires
